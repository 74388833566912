<template>
  <div class="pc-page-content">
    <div class="pc-menu-operation-panel operation-panel">
      <el-button type="primary" @click="refresh()">刷新</el-button>
      <el-button
        type="primary"
        @click="toAddDepartmentFn"
        v-if="this.userId === '1'"
        >添加部门</el-button
      >
      <el-button
        type="primary"
        @click="toAddPositionFn"
        v-if="this.userId === '1'"
        >添加岗位</el-button
      >
      <el-button type="primary" @click="goBack">返回</el-button>
    </div>
    <el-tabs type="border-card">
      <el-tab-pane label="部门岗位图">
        <div class="pc-menu-tree pc-tree">
          <el-tree
            class="filter-tree"
            :data="treeDataDepartment"
            :props="defaultProps"
            node-key="id"
            :default-expanded-keys="defaultExpandedKeysDepartment"
            :highlight-current="true"
            :expand-on-click-node="false"
            :filter-node-method="filterNode"
            @node-click="handleNodeClick"
            ref="elTree"
          >
            <template #default="{ node, data }">
              <span class="custom-tree-node">
                <img
                  src="../../../assets/images/img_icon_department.png"
                  v-if="data.icon === 'department'"
                />
                <img
                  src="../../../assets/images/img_icon_jobs.png"
                  alt=""
                  v-else
                />
                <span>{{ node.label }}</span>
                <span>
                  <el-button
                    v-if="
                      data.menuCode === 'department' &&
                      data.id != 3 &&
                      userId === '1'
                    "
                    type="text"
                    size="mini"
                    @click="toModifyDepartmentFn(data)"
                    >编辑</el-button
                  >
                  <el-button
                    v-if="
                      data.menuCode === 'department' &&
                      data.id != 3 &&
                      userId === '1'
                    "
                    type="text"
                    size="mini"
                    @click="deleteDepartmentFn(data)"
                    >删除</el-button
                  >
                  <el-button
                    v-if="
                      data.menuCode === 'position' &&
                      data.id != 2 &&
                      userId === '1'
                    "
                    type="text"
                    size="mini"
                    @click="toModifyPositionFn(data)"
                    >编辑</el-button
                  >
                  <el-button
                    v-if="
                      data.menuCode === 'position' &&
                      data.id != 2 &&
                      userId === '1'
                    "
                    type="text"
                    size="mini"
                    @click="deletePositionFn(data)"
                    >删除</el-button
                  >
                </span>
              </span>
            </template>
          </el-tree>
        </div>
      </el-tab-pane>
      <el-tab-pane
        label="上下级关系图"
        style="overflow-x: auto; overflow-y: hidden"
      >
        <div class="pc-menu-tree pc-tree">
          <el-tree
            class="filter-tree"
            :data="treeData"
            :props="defaultProps"
            node-key="id"
            :default-expanded-keys="defaultExpandedKeys"
            :highlight-current="true"
            :expand-on-click-node="false"
            :filter-node-method="filterNode"
            @node-click="handleNodeClick"
            ref="elTree"
          >
            <template #default="{ node, data }">
              <span class="custom-tree-node">
                <span>{{ node.label }}</span>
                <span>
                  <el-button
                    type="text"
                    v-if="data.id != 2 && userId === '1'"
                    size="mini"
                    @click="toModifyPositionFn(data)"
                    >编辑</el-button
                  >
                  <el-button
                    type="text"
                    v-if="data.id != 2 && userId === '1'"
                    size="mini"
                    @click="deletePositionFn(data)"
                    >删除</el-button
                  >
                </span>
              </span>
            </template>
          </el-tree>
        </div>
      </el-tab-pane>
    </el-tabs>
    <el-dialog
      width="600px"
      :title="dialogName"
      v-model="isShowEditDepartmentDialog"
      size="tiny"
      @close="isShowEditDepartmentDialog = false"
      custom-class="dialogArea frame"
    >
      <pc-edit-department
        :type="methodType"
        :formData="editDepartmentData"
        v-if="isShowEditDepartmentDialog"
        ref="editDepartmentForm"
      />
      <template #footer>
        <div class="dialog-footer">
          <el-button
            size="small"
            :loading="$store.getters.getButtonLoading"
            type="primary"
            @click="saveDepartmentFn"
            >保存</el-button
          >
          <el-button size="small" @click="isShowEditDepartmentDialog = false"
            >取消</el-button
          >
        </div>
      </template>
    </el-dialog>
    <el-dialog
      width="600px"
      :title="dialogName"
      v-model="isShowEditPositionDialog"
      size="tiny"
      @close="isShowEditPositionDialog = false"
      custom-class="dialogArea frame"
    >
      <pc-edit-position
        :type="methodType"
        :formData="editPositionData"
        v-if="isShowEditPositionDialog"
        ref="editPositionForm"
      />
      <template #footer>
        <div class="dialog-footer">
          <el-button
            size="small"
            :loading="$store.getters.getButtonLoading"
            type="primary"
            @click="savePositionFn"
            >保存</el-button
          >
          <el-button size="small" @click="isShowEditPositionDialog = false"
            >取消</el-button
          >
        </div>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import PcEditDepartment from './editDepartment.vue';
import PcEditPosition from './editPosition.vue';
import { erpConfirm, errorMessage } from '@/utils/publicMethods';
import { httpPost } from '@/api';
export default {
  data() {
    return {
      userId: '',
      isShowEditDepartmentDialog: false,
      isShowEditPositionDialog: false,
      filterText: '',
      treeData: [],
      dialogName: '',
      editPositionData: {},
      editDepartmentData: {},
      treeDataDepartment: [],
      methodType: '',
      currentId: '',
      editMenuFormData: {},
      defaultExpandedKeys: [],
      defaultExpandedKeysDepartment: [],
      defaultProps: {
        children: 'subMenu',
        label: 'menuName',
      },
    };
  },
  watch: {
    filterText(val) {
      this.$refs.elTree.filter(val);
    },
  },
  created() {
    this.initMenuTree();
    this.userId = this.$store.getters.getUserId; // 获取用户id
  },
  components: {
    PcEditDepartment,
    PcEditPosition,
  },
  methods: {
    deletePositionFn(position) {
      const confirmMsg =
        '确定删除' +
        position.menuName +
        '吗? 删除后将不能使用，确定继续删除吗?';
      erpConfirm(confirmMsg).then(async () => {
        const params = {};
        const res = await httpPost(
          `/uac/position/deleteById/${position.id}`,
          params
        );
        if (res.code === 200) {
          this.initMenuTree();
        } else {
          errorMessage(res.message);
        }
      });
    },
    deleteDepartmentFn(department) {
      const confirmMsg =
        '确定删除' +
        department.menuName +
        '吗? 删除后将不能使用，确定继续删除吗?';
      erpConfirm(confirmMsg).then(async () => {
        const params = {};
        const res = await httpPost(
          `/uac/department/deleteById/${department.id}`,
          params
        );
        if (res.code === 200) {
          this.initMenuTree();
        } else {
          errorMessage(res.message);
        }
      });
    },
    goBack() {
      this.$router.go(-1);
    },
    initMenuTree() {
      this.ajax({
        url: '/uac/department/getTree/',
        loading: true,
        success: (res) => {
          const data = res.result;
          if (!data) {
            return;
          }
          this.treeDataDepartment = data;
          this.defaultExpandedKeysDepartment.push(
            this.treeDataDepartment[0].id
          );
        },
      });
      this.ajax({
        url: '/uac/position/getTree/',
        loading: true,
        success: (res) => {
          const data = res.result;
          if (!data) {
            return;
          }
          this.treeData = data;
          this.defaultExpandedKeys.push(this.treeData[0].id);
        },
      });
    },
    refresh() {
      this.initMenuTree();
    },
    toAddDepartmentFn() {
      this.methodType = 'ADD';
      this.dialogName = '添加部门';
      this.isShowEditDepartmentDialog = true;
      this.editDepartmentData = {};
    },
    toModifyDepartmentFn(department) {
      this.isShowEditDepartmentDialog = true;
      this.methodType = 'UPDATE';
      this.dialogName = '编辑部门';
      this.editDepartmentData = department;
    },
    saveDepartmentFn() {
      this.$refs.editDepartmentForm.submitForm(() => {
        this.isShowEditDepartmentDialog = false;
        this.initMenuTree();
      });
    },
    toAddPositionFn() {
      this.methodType = 'ADD';
      this.dialogName = '添加岗位';
      this.isShowEditPositionDialog = true;
      this.editPositionData = {};
    },
    toModifyPositionFn(position) {
      this.isShowEditPositionDialog = true;
      this.methodType = 'UPDATE';
      this.dialogName = '编辑岗位';
      this.editPositionData = position;
    },
    savePositionFn() {
      this.$refs.editPositionForm.submitForm(() => {
        this.isShowEditPositionDialog = false;
        this.initMenuTree();
      });
    },
    handleNodeClick(data) {
      if (!data.id) {
        return;
      }
      this.currentId = data.id;
    },
    filterNode(value, data) {
      if (!value) return true;
      console.info(value, data);
      return data.menuName.indexOf(value) !== -1;
    },
  },
};
</script>
<style lang="scss">
.pc-menu-operation-panel {
  margin-bottom: 20px;
}
.pc-menu-tree {
  display: inline-block;
}
.pc-page-content .pc-menu-tree .el-tree-node__content {
  padding-right: 20px;
}
.pc-menu-edit-panel {
  display: inline-block;
  vertical-align: top;
}
.pc-menu-header {
  margin: 0 0 20px 20px;
}
.operation-panel {
  margin: 20px 0;

  .el-button + .el-button {
    margin-left: 1px;
  }

  button:first-child {
    border-radius: 16.5px 0 0 16.5px !important;
  }

  button:last-child {
    border-radius: 0 16.5px 16.5px 0 !important;
  }
}
</style>
