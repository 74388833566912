<template>
  <div class="pc-page-main">
    <el-form
      :model="formDataCurrent"
      :rules="rules"
      ref="positionForm"
      label-width="80px"
      class="no-pos"
    >
      <el-form-item label="岗位名称" prop="name">
        <el-input
          size="small"
          maxlength="10"
          clearable
          v-model="formDataCurrent.name"
          placeholder="请输入内容"
        ></el-input>
      </el-form-item>
      <el-form-item label="所属部门" prop="departmentId" v-if="type === 'ADD'">
        <el-select
          size="small"
          v-model="formDataCurrent.departmentId"
          filterable
          placeholder="所属部门"
        >
          <el-option
            v-for="item in departmentList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        label="所属部门"
        prop="departmentId"
        v-if="type === 'UPDATE'"
      >
        <el-select
          size="small"
          v-model="formDataCurrent.departmentId"
          disabled
          filterable
          placeholder="所属部门"
        >
          <el-option
            v-for="item in departmentList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="上级岗位" prop="number" v-if="type === 'ADD'">
        <el-select
          size="small"
          v-model="formDataCurrent.pid"
          filterable
          placeholder="上级岗位"
        >
          <el-option
            v-for="item in positionList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="上级岗位" prop="number" v-if="type === 'UPDATE'">
        <el-select
          size="small"
          v-model="formDataCurrent.pid"
          disabled
          filterable
          placeholder="上级岗位"
        >
          <el-option
            v-for="item in positionList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
    </el-form>
  </div>
</template>
<script type="text/ecmascript-6">
export default {
  props: {
    formData: {
      id: '',
      pid: '',
      departmentId: '',
      name: ''
    },
    type: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      formDataCurrent: {
        id: '',
        pid: '',
        departmentId: '',
        name: ''
      },
      departmentList: [],
      positionList: [],
      rules: {
        name: [
          { required: true, message: '内容不能为空', trigger: 'blur' }
        ]
      }

    };
  },
  created () {
    this.initDepartmentList();
    this.initPositionList();
    if (this.type === 'UPDATE') {
      this.initPosition();
    }
  },
  computed: {
    formPropName () {
      return function (prop) {
        return this.type === 'UPDATE' ? '' : prop;
      };
    }
  },
  methods: {
    initDepartmentList () {
      this.ajax({
        url: '/uac/department/getDepartmentListAll',
        loading: true,
        success: (res) => {
          const data = res.result;
          if (!data) {
            return;
          }
          this.departmentList = data;
        }
      });
    },
    initPositionList () {
      this.ajax({
        url: '/uac/position/getPositionList',
        loading: true,
        success: (res) => {
          const data = res.result;
          if (!data) {
            return;
          }
          this.positionList = data;
        }
      });
    },
    initPosition () {
      this.ajax({
        url: '/uac/position/getPositionById/' + this.formData.id,
        loading: true,
        success: (res) => {
          const data = res.result;
          if (!data) {
            return;
          }
          this.formDataCurrent = data;
        }
      });
    },
    submitForm (fn) {
      this.$refs.positionForm.validate((valid) => {
        if (valid) {
          this.saveDepartmentFn(fn);
        } else {
          return false;
        }
      });
    },
    saveDepartmentFn (fn) {
      this.ajax({
        url: '/uac/position/save',
        data: this.formDataCurrent || '',
        loading: true,
        success: (res) => {
          fn && fn(res);
          if (res.code === 200) {
            this.successTip('操作成功');
          }
        }
      });
    }
  }
};
</script>
<style rel="stylesheet/scss" lang="scss" scoped>
.menu-cascader {
  width: 100%;
  .el-cascader__label {
    line-height: 36px;
  }
}
.pc-page-main {
  padding: 8px 15px !important;
}
.el-input {
  width: 420px;
}
</style>
