<template>
  <div class="pc-page-main">
    <el-form
      :model="formDataCurrent"
      :rules="rules"
      ref="departmentForm"
      label-width="80px"
      class="no-pos"
    >
      <el-form-item label="部门名称" prop="name">
        <el-input
          size="small"
          maxlength="10"
          clearable
          v-model="formDataCurrent.name"
          placeholder="请输入内容"
        ></el-input>
      </el-form-item>
      <el-form-item label="上级部门" prop="pid" v-if="type === 'ADD'">
        <el-select
          size="small"
          v-model="formDataCurrent.pid"
          filterable
          placeholder="上级部门"
        >
          <el-option
            v-for="item in departmentList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="上级部门" prop="pid" v-if="type === 'UPDATE'">
        <el-select
          size="small"
          v-model="formDataCurrent.pid"
          disabled
          filterable
          placeholder="上级部门"
        >
          <el-option
            v-for="item in departmentList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
export default {
  props: {
    formData: {
      id: '',
      pid: '',
      name: '',
    },
    type: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      formDataCurrent: {
        id: '',
        pid: '',
        name: '',
      },
      departmentList: [],
      rules: {},
    };
  },
  created() {
    this.initDepartmentList();
  },
  computed: {
    formPropName() {
      return function (prop) {
        return this.type === 'UPDATE' ? '' : prop;
      };
    },
  },
  methods: {
    initDepartment() {
      this.ajax({
        url: '/uac/department/getDepartmentById/' + this.formData.id,
        loading: true,
        success: (res) => {
          const data = res.result;
          if (!data) {
            return;
          }
          this.formDataCurrent = data;
        },
      });
    },
    initDepartmentList() {
      this.ajax({
        url: '/uac/department/getDepartmentListAll',
        loading: true,
        success: (res) => {
          const data = res.result;
          if (!data) {
            return;
          }
          this.departmentList = data;
          if (this.type === 'UPDATE') {
            this.initDepartment();
          }
        },
      });
    },
    submitForm(fn) {
      this.$refs.departmentForm.validate((valid) => {
        if (valid) {
          this.saveDepartmentFn(fn);
        } else {
          return false;
        }
      });
    },
    saveDepartmentFn(fn) {
      console.info('this.formDataCurrent', this.formDataCurrent);
      this.ajax({
        url: '/uac/department/save',
        data: this.formDataCurrent || '',
        loading: true,
        success: (res) => {
          fn && fn(res);
          if (res.code === 200) {
            this.successTip('操作成功');
          }
        },
      });
    },
  },
};
</script>
<style rel="stylesheet/scss" lang="scss" scoped>
.menu-cascader {
  width: 100%;
  .el-cascader__label {
    line-height: 36px;
  }
}
.pc-page-main {
  padding: 28px 5px !important;
}
.el-input {
  width: 420px;
}
</style>
